<template>
 <v-theme-provider>
  <base-section
    id="generalIndusagent"
    space="36"
  >
    <v-spacer />

    <v-container>
      <base-section-heading title="일반 산업용 세척제" />
        <v-container
          class="text-center"
        >
          수처리용 및 철강, 비철금속용에서 최첨단 반도체용까지 ㈜남강의 선진 기술력은 고객이 원한 SOLUTION을 제공해 드립니다.
        </v-container>
      <v-card
          max-width="1080"
          class="mx-auto"
        >
          <v-list
          three-line
          >
            <template v-for="(info, index) in iteminfos">
              <v-divider
                v-if="info.divider"
                :key="index"
              ></v-divider>

              <v-list-item
                v-else
                :key="info.title"
              >
                <v-list-item-avatar>
                  <v-img :src="info.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="info.title"></v-list-item-title>
                  <v-card-text v-html="info.subtitle"> </v-card-text>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
    </v-container>
  </base-section>
 </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectiongeneralIndusagent',
    drawer: null,
    data: () => ({
      drawer: null,
      inset: false,
      iteminfos: [
        { header: 'Today' },
        {
          avatar: '',
          title: '냉각수 수질부패방지, 이끼억제제',
          subtitle: '&mdash; 저농도에서 박테리아, 곰팡이균 등에 살균효과가 뛰어 납니다.<br>이끼류 등 스라임 부착에 대한 뛰어난 방지효과를 나타내며, 살균 지속성이 우수하고, 기포가 없습니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '냉각수 레지오넬라균 살균제',
          subtitle: '&mdash; 냉각수 타워에서 비산되는 Legionella균 방지에 탁월한 효과가 있습니다.<br>시설물의 부식방지와 이끼발생을 동시에 억제하며, 사상균, 잡균 등의 완전 멸균에 의한 악취제거 효과가 뛰어 납니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '냉동기 및 에어컨 알루미늄 코일용 세척제',
          subtitle: '&mdash; 장기가 가동된 냉동기 에어컨 CELL은 습기와 불결한 먼지로 인하여 라지오넬라균을 포함한 각종 세균의 서식처가 되며,<br> 기름 먼지로 막힌 CELL은 제거기능 발휘 할 수 없어 전기 소모가 많아 집니다.<br>이 경우 본 세제를 사용함으로써 냉동기 에어컨에 서식하는 병원균 제거 및 에너지 절약 효과가 있습니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '은기물 및 동제품 광택용 세척제',
          subtitle: '',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '비철금속 탈지용 세척제',
          subtitle: '&mdash; 기름때 세척력이 우수하며, 비철금속 재질에 손상을 주지 않습니다.<br>알루미늄 생산공정에 사용하여도 우수한 탈지효과를 주며 탈지불량이 생기지 않습니다.<br>저기포성으로 작업이 편리하며, 생분해도가 높고 소포도가 우수하여 폐수처리시 오니에 무리가 가지 않습니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '냉연강판등 전해탈지제',
          subtitle: '&mdash; 탈지 및 방청유 유화력이 우수하며, 오물 제거 및 재부착 방지력이 뛰어납니다. <br>Non-silicate 타입으로 다음 피막공정의 불량을 발생시키지 않습니다. 생분해도가 높고 소포도가 우수하여 폐수처리시 오니에 무리가 가지 않습니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '지하철 자량용, 대차 세척제',
          subtitle: '',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '광학렌즈 세척제',
          subtitle: '',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '초음파용 세척제',
          subtitle: '',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '반도체용 실리콘 웨이퍼 세척제',
          subtitle: '',
        },
      ],
    }),
    provide: {
      theme: { isDark: false },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}
.v-list-item__title {
    font-size: 18px;
    color: coral !important;
}

</style>
